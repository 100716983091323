import { CreateApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type RadiusSummary = {
  id: string;
  sk: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  username: string;
  password?: string;
  rsp: string;
  buildingId: string;
  technologyType: 'IPoE' | 'PPPoE' | 'IPoEPPPoE' | 'Unknown';
  framedIpAddress?: string;
  framedPool?: string;
  framedRoute?: string[];
  erxEgressPolicyName?: string;
  erxIngressPolicyName?: string;
  erxVirtualRouterName?: string;
  extraReplyAttributes?: Record<string, string>;
  speedUploadMbps: number;
  speedDownloadMbps: number;
};

export type RadiusListResponse = {
  items: RadiusSummary[];
  lastEvaluatedKey?: string;
};

export type ErrorResponse = {
  code: number;
  message: string;
};

export type CreateRadiusRequest = {
  status: 'Active' | 'Cancelled' | 'Disabled' | 'Paused'
  username: string;
  buildingId: string;
  speedUploadMbps: number;
  speedDownloadMbps: number;
  domain: string;
  rsp: string;
  technologyType: 'IPoE' | 'PPPoE' | 'IPoEPPPoE' | 'Unknown';
  framedIpAddress?: string;
  framedRoute?: string[];
  framedPool?: string;
  extraReplyAttributes?: Record<string, string>;
  password?: string;
};

export type BatchCreateRadiusRequest = CreateRadiusRequest[]

export type BatchDeleteRequest = {
  items: string[];
};

export const radiusApi = createApi({
  reducerPath: 'radiusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/proctor', // Adjust base URL as needed
    prepareHeaders: (headers, { getState }) => {
      const cookies = document.cookie
        .split('; ')
        .find((row) => row.startsWith('jolly='))
        ?.split('=')[1];

      if (cookies) {
        headers.set('Authorization', `Bearer ${cookies}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Radius', 'Radius_LIST', 'Radius_SEARCH_LIST'],
  endpoints: (builder) => ({
    // List Radius Entities
    listRadiusSummaries: builder.query<RadiusListResponse, {limit?: number; lastEvaluatedKey?: string}>({
      query: ({ limit = 100, lastEvaluatedKey }) => ({
        url: '/radius',
        params: { limit, lastEvaluatedKey },
      }),
      providesTags: (result) => (result
        ? [
          ...result.items.map((item) => ({ type: 'Radius' as const, id: item.id })), // Use `as const` for type inference
          { type: 'Radius_LIST' as const, id: 'LIST' }, // Use `as const` for the list tag
        ]
        : [{ type: 'Radius_LIST' as const, id: 'LIST' }]), // Fallback
    }),

    // Search Radius Summaries
    searchRadiusSummaries: builder.query<
      RadiusListResponse,
      {searchString: string; limit?: number; lastEvaluatedKey?: string; attribute?: string}
    >({
      query: ({
        searchString, limit = 100, lastEvaluatedKey, attribute,
      }) => ({
        url: `/radius/search/${encodeURIComponent(searchString)}`,
        params: { limit, lastEvaluatedKey, attribute },
      }),
      providesTags: (result) => (result
        ? [
          ...result.items.map((item) => ({ type: 'Radius' as const, id: item.id })), // Use `as const` for type inference
          { type: 'Radius_LIST' as const, id: 'LIST' }, // Use `as const` for the list tag
        ]
        : [{ type: 'Radius_LIST' as const, id: 'LIST' }]), // Fallback
    }),

    // Get Radius Details for a User
    getRadiusDetails: builder.query<RadiusSummary, {username: string}>({
      query: ({ username }) => ({
        url: `/radius/${encodeURIComponent(username)}`,
      }),
      providesTags: ['Radius'],
    }),

    // Batch Create RADIUS Users
    batchCreateRadiusUsers: builder.mutation<RadiusListResponse, BatchCreateRadiusRequest>({
      query: (body) => ({
        url: '/radius/bulk',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => (result
        ? [
          ...result.items.map((item) => ({ type: 'Radius' as const, id: item.id })), // Use `as const` for individual items
          { type: 'Radius_LIST' as const, id: 'LIST' }, // Use `as const` for the list tag
        ]
        : [{ type: 'Radius_LIST' as const, id: 'LIST' }]), // Fallback

    }),

    // Batch Delete RADIUS Users
    batchDeleteRadiusUsers: builder.mutation<void, BatchDeleteRequest>({
      query: (body) => ({
        url: '/radius/bulk',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (result, error, arg) => (arg.items
        ? [
          ...arg.items.map((id) => ({ type: 'Radius' as const, id })),
          { type: 'Radius_LIST' as const, id: 'LIST' },
        ]
        : [{ type: 'Radius_LIST' as const, id: 'LIST' }]),
    }),
  }),
});

// Export hooks for components
export const {
  useListRadiusSummariesQuery,
  useSearchRadiusSummariesQuery,
  useGetRadiusDetailsQuery,
  useBatchCreateRadiusUsersMutation,
  useBatchDeleteRadiusUsersMutation,
} = radiusApi;
