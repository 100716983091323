import React, { useState, useEffect } from 'react';
import { useForm, FieldValues, useFieldArray } from 'react-hook-form';
import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  MenuItem,
  Select,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  InputLabel,
  FormControl,
  Snackbar,
  Chip,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { sum } from 'lodash';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Plans } from './tariff';
import UsernameField, { AuthProtocol } from './UsernameField';
import {
  BatchCreateRadiusRequest, CreateRadiusRequest, RadiusSummary, useBatchCreateRadiusUsersMutation,
} from './query/query';
import { isValidCIDR, isValidIP } from './utils';
import { FormValues } from './FormValues';

export interface SubscriberFormProps {
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
  setSnackBarMessage: (arg: string) => void
  summary?: RadiusSummary
}

const SubscriberForm: React.FC<SubscriberFormProps> = ({
  isOpen, setIsOpen, setSnackBarMessage, summary = undefined,
}) => {
  const {
    register,
    reset,
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    defaultValues: {
      routes: summary?.framedRoute ? summary?.framedRoute.map((route, idx) => ({ id: `${route}-${idx}`, value: route })) : [],
      routes_new: '',
    },
  });

  const usernameField = register('username', {
    validate: (value) => (value && value.split('@')[0].length > 0),
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const passwordField = register('password', { required: true });
  const statusField = register('status', { required: true });
  const tariffField = register('tariff', { required: true });
  const buildingIdField = register('buildingId', { validate: (val) => (val !== '' ? true : 'building id is required') });
  const poolOrIp = (summary?.framedPool && summary?.framedPool !== '' ? 'ip_pool' : 'ip_address');

  const [
    networkField,
    setNetworkField,
  ] = useState<string>(summary?.framedPool === 'CGNAT-POOL' ? 'cgnat' : poolOrIp);

  const [
    authProtocolField,
    setAuthProtocolField,
  ] = useState<AuthProtocol>(summary?.technologyType ? summary?.technologyType : 'PPPoE');

  const ipField = register('ip', {
    required: networkField !== 'cgnat',
    validate: (val) => (val && !isValidIP(val) && val !== 'CGNAT-POOL' && networkField !== 'cgnat' ? 'must be a valid ip' : true),
  });

  const handleChange = (
    e: React.MouseEvent<HTMLElement>,
    newNetworkField: string,
  ) => {
    e.preventDefault();
    if (newNetworkField == null) {
      return;
    }
    if (networkField === 'cgnat' && newNetworkField === 'ip') {
      setNetworkField('');
    } else {
      setNetworkField(newNetworkField);
    }
  };

  const handleAuthProtocolFieldChange = (
    e: React.MouseEvent<HTMLElement>,
    newAuthProtocolField: AuthProtocol,
  ) => {
    e.preventDefault();
    if (newAuthProtocolField === null) {
      return;
    }
    setAuthProtocolField(newAuthProtocolField);
  };

  const handleClose = (e: React.SyntheticEvent) => {
    e.preventDefault();
    reset();
  };

  const [bulkCreate, {
    isLoading,
  }] = useBatchCreateRadiusUsersMutation();

  useEffect(() => {
    if (summary && summary?.username) {
      const id = (Plans.find((p) => p.down === summary.speedDownloadMbps && p.up === summary.speedUploadMbps)?.id || 0);
      if (summary?.framedPool && summary?.framedPool !== '') {
        setValue('ip', summary.framedPool);
      } else {
        setValue('ip', summary?.framedIpAddress || '');
      }
      setValue('username', summary.username);
      setValue('buildingId', summary.buildingId);
      setValue('framedPool', summary?.framedPool || '');
      setValue('tariff', id);
    }
  }, []);

  const postData = async ({
    username, ip, tariff, buildingId, framedPool, password, status, routes,
  }: FieldValues) => {
    const plan = Plans.find((p) => p.id === tariff) || { down: 0, up: 0 };

    const userparts = username.split('@');

    const subscriber: CreateRadiusRequest = {
      username: userparts[0],
      domain: userparts[1],
      rsp: userparts[1],
      speedDownloadMbps: plan?.down,
      speedUploadMbps: plan?.up,
      technologyType: authProtocolField,
      framedRoute: routes.map((route: {id: number, value: string}) => route.value),
      buildingId,
      framedPool,
      status,
      password,
    };

    if (networkField === 'cgnat') {
      subscriber.framedPool = 'CGNAT-POOL';
    } else if (networkField === 'ip_pool') {
      subscriber.framedPool = ip;
    } else {
      subscriber.framedPool = '';
      subscriber.framedIpAddress = ip;
    }

    try {
      const data = await bulkCreate([subscriber]).unwrap();
      setSnackBarMessage(`subscriber ${data?.items[0].username} has been created succcessfully.`);
      setIsOpen(false);
    } catch (err) {
      setSnackBarMessage(String(err?.data) || 'error occurred');
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'routes',
  });

  const onAddRoute = () => {
    const newRoute = getValues('routes_new')?.trim();
    if (!newRoute) return;

    if (!isValidCIDR(newRoute)) {
      setValue('routes_new', newRoute);
      return;
    }

    if (fields.some((field) => field.value === newRoute)) {
      return; // Prevent duplicate routes
    }

    append({ id: String(Date.now()), value: newRoute });
    setValue('routes_new', ''); // Clear input field
  };

  const onDeleteRoute = (index: number) => {
    remove(index);
  };

  const handlePasswordGen = (evt: any) => {
    evt.preventDefault();
    const chars = 'abcdefghijklmnopqrstubwsyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let pw = '';
    for (let index = 0; index < 10; index += 1) {
      pw += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setValue('password', pw);
  };

  return (
    <Modal
      open
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ minWidth: '400px' }}>
        <Box
          component="form"
          onSubmit={handleSubmit(
            (d: FieldValues) => {
              postData(d);
            },
          )}
        >
          <CardHeader
            component="div"
            title="New subscriber"
          />
          <CardContent>
            <Box sx={{ mb: 1.5 }}>
              <Typography
                variant="body1"
                component="div"
              >
                Authentication
              </Typography>
              <InputLabel
                sx={{
                  position: 'relative',
                  top: '1em',
                  ml: 1.5,
                  backgroundColor: 'white',
                  zIndex: 1,
                  display: 'inline-block',
                  padding: '0 0.4em',
                }}
                size="small"
                shrink
              >
                Protocol

              </InputLabel>
              <ToggleButtonGroup
                sx={{ mb: 0.5 }}
                exclusive
                value={authProtocolField}
                onChange={handleAuthProtocolFieldChange}
                fullWidth
              >
                <ToggleButton
                  color="primary"
                  value="PPPoE"
                >
                  PPPoE
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="IPoE"
                >
                  IPoE
                </ToggleButton>
              </ToggleButtonGroup>
              <UsernameField
                defaultValue={summary?.username || ''}
                setValue={setValue}
                authProtocol={authProtocolField}
                name={usernameField.name}
                error={!!errors.username}
                helperText={
                errors.username?.message
                  ? errors.username.message.toString()
                  : ''
                }
                required
              />

              <Box>
                <Typography
                  variant="body1"
                  component="div"
                >
                  Password
                </Typography>
                <FormControl
                  margin="dense"
                  fullWidth
                >
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    onChange={passwordField.onChange}
                    onBlur={passwordField.onBlur}
                    inputRef={passwordField.ref}
                    defaultValue={summary?.password || ''}
                    name={passwordField.name}
                    error={!!errors.password}
                    InputLabelProps={{ shrink: true }}
                    helperText={errors.password?.message ? errors.password.message.toString() : ''}
                    id="password"
                    label="Password"
                    margin="dense"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Button color="success" onClick={handlePasswordGen}>Generate Password</Button>
              </Box>
            </Box>
            <Box sx={{ mb: 1.5 }}>
              <Typography
                variant="body1"
                component="div"
              >
                Network
              </Typography>
              <ToggleButtonGroup
                sx={{ mt: 1, mb: 1.5 }}
                exclusive
                value={networkField}
                onChange={handleChange}
                fullWidth
              >
                <ToggleButton
                  color="primary"
                  value="ip_address"
                >
                  IP Address
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="ip_pool"
                >
                  IP Pool
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="cgnat"
                >
                  CGNAT
                </ToggleButton>
              </ToggleButtonGroup>
              {networkField !== 'cgnat'
              && (
              <TextField
                sx={{ mt: 0 }}
                error={!!errors.ip}
                helperText={
                errors.ip?.message
                  ? errors.ip.message.toString()
                  : ''
                }
                onChange={ipField.onChange}
                onBlur={ipField.onBlur}
                inputRef={ipField.ref}
                name={ipField.name}
                id="ip_address"
                label={networkField === 'ip_address'
                  ? 'IP Address'
                  : 'IP Pool'}
                margin="normal"
                fullWidth
              />
              )}

              <Box sx={{
                display: 'flex', gap: 2, alignItems: 'center', mt: 2,
              }}
              >
                <TextField
                  sx={{ mt: 0 }}
                  error={!!errors.routes_new}
                  helperText={
                    errors?.routes_new?.message
                      ? errors?.routes_new?.message.toString()
                      : ''
                  }
                  {...register('routes_new', {
                    validate: (value) => (value && !isValidCIDR(value) ? 'Invalid CIDR format (e.g., 192.168.1.0/24)' : true),
                  })}
                  id="routes_new"
                  label="Framed Routes"
                  margin="normal"
                  fullWidth
                />
                <Button variant="contained" onClick={onAddRoute}>OK</Button>
              </Box>
              <Box sx={{
                mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1,
              }}
              >
                {fields.map((field, index) => (
                  <Chip
                    key={field.id}
                    label={field.value}
                    onDelete={() => onDeleteRoute(index)}
                    color="primary"
                  />
                ))}
              </Box>
            </Box>
            <Box>
              <Typography
                variant="body1"
                component="div"
              >
                Tariff information
              </Typography>
              <FormControl
                margin="dense"
                fullWidth
              >
                <InputLabel id="tariff-plan-label">Tariff plan</InputLabel>
                <Select
                  onChange={tariffField.onChange}
                  onBlur={tariffField.onBlur}
                  inputRef={tariffField.ref}
                  name={tariffField.name}
                  error={!!errors.tariff}
                  id="tariff-plan"
                  label="Tariff plan"
                  defaultValue={summary?.speedDownloadMbps ? (Plans.find((p) => p.down === summary.speedDownloadMbps && p.up === summary.speedUploadMbps)?.id || 0) : 0}
                >
                  {Plans.map((tariff) => (
                    <MenuItem
                      key={tariff.id}
                      value={tariff.id}
                    >
                      {tariff.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Typography
                variant="body1"
                component="div"
              >
                Building ID
              </Typography>
              <FormControl
                margin="dense"
                fullWidth
              >
                <TextField
                  sx={{ mt: 0 }}
                  error={!!errors.buildingId}
                  helperText={
                    errors?.buildingId?.message
                      ? errors?.buildingId.message.toString()
                      : ''
                  }
                  margin="normal"
                  fullWidth
                  onChange={buildingIdField.onChange}
                  onBlur={buildingIdField.onBlur}
                  inputRef={buildingIdField.ref}
                  name={buildingIdField.name}
                  id="buildingId"
                  label="Building ID"
                />
              </FormControl>
            </Box>
            <Box>
              <Typography
                variant="body1"
                component="div"
              >
                Status
              </Typography>
              <FormControl
                margin="dense"
                fullWidth
              >
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  onChange={statusField.onChange}
                  onBlur={statusField.onBlur}
                  inputRef={statusField.ref}
                  name={statusField.name}
                  error={!!errors.status}
                  id="status"
                  label="Status"
                  defaultValue={summary?.status}
                >
                  {['Active', 'Cancelled', 'Disabled', 'Paused'].map((status) => (
                    <MenuItem
                      key={status}
                      value={status}
                    >
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </CardContent>
          <CardActions>
            <ButtonGroup fullWidth>
              <Button
                size="large"
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Loading ...' : 'Create'}
              </Button>
              <Button
                size="large"
                color="error"
                variant="outlined"
                type="submit"
                disabled={isLoading}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </CardActions>
        </Box>
      </Card>
    </Modal>
  );
};

export default SubscriberForm;
